import React, { useState } from 'react'
 import Navbar from './navbar'
 import Footer from './footer'
 import Slider from "react-slick";
 import "slick-carousel/slick/slick.css"
 import "slick-carousel/slick/slick-theme.css"

 import hasbanner1 from '../images/has/banners/has-banner-1.png'

 import reportmanagement from '../images/has/modules/report-management.png'
 import rolemanagement from '../images/has/modules/role-management.png'
 import datamanagement from '../images/has/modules/data-management.png'
 import appointmentsmanagement from '../images/has/modules/appointments-management.png'
 import usermanagement from '../images/has/modules/user-management.png'

 import sliderbg from '../images/slider-bg.jpg'
 import testimonialsbg from '../images/testimonials-bg.jpg'
 import bg1 from '../images/bg-1.jpg'
 import bg2 from '../images/bg-2.jpg'

 const DigiHealthcareAppointmentSystem = () => {

  const settings = {
    dots: true, 
    infinite: true,
    speed: 500, 
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };
	 
  const [activeIndex, setActiveIndex] = useState(null);

  const testimonialsData = [
    {
      name: "Dr. Linda Evans",
      company: "Medical Director, Family Care Clinic",
      description: "The DHAS Appointment System has completely transformed how we manage patient bookings. It's user-friendly, reduces wait times, and has improved both patient satisfaction and staff efficiency."
    },
    {
      name: "James Williams",
      company: "Operations Manager, Sunrise Health Center",
      description: "Since implementing DHAS, our appointment scheduling process has become so much smoother. The automated reminders help reduce no-shows, and the system's integration with our EMR is seamless."
    },
    {
      name: "Dr. Sarah Thompson",
      company: "Orthopedic Surgeon, Premier Orthopedics",
      description: "DHAS Scheduler has simplified our appointment management. The ability to see real-time availability, patient preferences, and quickly reschedule appointments has enhanced the experience for both patients and our staff."
    },
    {
      name: "Rachel Green",
      company: "Patient Services Coordinator, Greenleaf Medical Group",
      description: "With DHAS, we’ve seen a dramatic reduction in scheduling errors. The software’s intuitive interface allows us to manage appointments across multiple locations, improving our overall workflow and patient experience."
    },
    {
      name: "Dr. Mark Johnson",
      company: "Chief of Staff, HealthPlus Family Practice",
      description: "The Digi Healthcare Appointment System is a game-changer. It's reduced administrative time significantly, allowing our medical staff to focus more on patient care. The real-time updates and online booking options have been a big hit with our patients."
    }
  ];
  
  const faqData = [
    {
      question: "What is a Healthcare Appointment System?",
      answer: "A Digi Healthcare Appointment System is a digital tool designed to manage and streamline the process of booking, scheduling, and tracking medical appointments. It allows patients to book appointments online, receive reminders, and communicate with healthcare providers, while enabling clinics or hospitals to efficiently manage their schedules and optimize patient flow."
    },
    {
      question: "How does a Digi Healthcare Appointment System benefit patients?",
      answer: "A Digi Healthcare Appointment System provides patients with the convenience of booking appointments online, anytime and anywhere. It reduces wait times by allowing patients to choose available slots, send reminders for upcoming appointments, and enable easy rescheduling or cancellations. This enhances patient satisfaction and helps them manage their healthcare needs efficiently."
    },
    {
      question: "Can the Digi Healthcare Appointment System integrate with other healthcare systems?",
      answer: "Yes, most digi lead management software can integrate with a wide range of other business tools, such as CRM systems, email marketing platforms, customer support software, and analytics tools. This integration ensures a seamless flow of data and helps create a more unified approach to lead generation and nurturing."
    },
    {
      question: "How do I measure the effectiveness of digi lead management software?",
      answer: "The effectiveness of digi lead management software can be measured by tracking key metrics such as lead conversion rate, sales cycle length, response time, and customer retention. Additionally, the software's ability to improve lead qualification and reduce manual tasks can also serve as indicators of its effectiveness."
    },
    {
      question: "Can I customize the Digi Healthcare Appointment System to fit my practice’s needs?",
      answer: "Yes, most Digi Healthcare Appointment Systems offer customization options to cater to the unique needs of your practice. You can configure features such as appointment types, duration, availability, and patient notifications. This flexibility allows the system to adapt to various specialties and practice sizes, ensuring a tailored experience for both providers and patients."
    }
  ];

  const toggleAnswer = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
	 
   return (
     <div>
         <Navbar />


             <div style={{ backgroundImage: `url(${sliderbg})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat' }}>
 <div
  className="blur-backdrop"
  style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--scrgb), .65), rgba(var(--tcrgb), .65)), url("")' }}
>
		 <div className="spacex">

  <div id="carouselExampleIndicators" className="homeslider carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="">
          <div className="sec-space"></div>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <div className="sec-space" />
            <h1 className="font-weight-bold text-white">
            Digi Healthcare Appointment System
            </h1>
            <p className="wc">
            Simplify scheduling, enhance patient care, and boost doctor efficiency—all in one seamless solution - DHAS
            </p>
            <div className="sec-space" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <img
              src={hasbanner1}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Lorem Ipsum"
            />
          </div>
        </div>
        <div className="sec-space"></div>
      </div>
        </div>
        
      </div>
    </div>
    </div>
    </div>
    </div>
    






    <div className="spacex">
    <div className="sec-space"></div>
         <h1 className="font-weight-bold text-center text-gradient">Healthcare Appointment System</h1>
         <p class="text-center">DHAS is most effective tool for streamlining hospital or clinic operations by efficiently scheduling every patient appointment based on each doctor’s availability. It offers a highly customizable appointment booking system with re-scheduling and cancellation options at a click of button. It reduces time wastage, enhance patient management and boost doctors’ efficiency and productivity through seamless integration with HIS</p>
         <div className="sec-space"></div>
     </div>  
     <div className="spacex" style={{
    backgroundImage:
      "linear-gradient(to right, rgba(var(--pcrgb), .10), rgba(var(--pcrgb), .10))"
  }}>  
     <div className="sec-space"></div>
		 <div className="row">
     <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
     <img
              src={usermanagement}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Users Management"
            />
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">

       <h4 class="font-weight-bold text-gradient">An Appointment Booking & Management</h4>
      
        <p>Simplify your hospital or clinic’s appointment booking process with our tool that keeps your operations running smoothly. Stay on top of real time booking updates and reach out the patient quickly. Automated notifications keep patients informed about their booking status, rescheduling, or cancellations via SMS and email alerts. The built-in reminder feature helps improve patient attendance rates and reduces no-shows, ensuring a smoother experience for both patients and staff.</p>
    </div>
    </div>
    <div className="sec-space"></div>
    </div>
    <div className="spacex">
      <div className="sec-space"></div>
    <div className="row">
    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">

      <h4 class="font-weight-bold text-gradient">Efficient Multi-specialties Appointment Management</h4>
      <p>Designed specifically for multi-specialty hospitals, this tool streamlines the flow of appointment bookings for any number of doctors across various specialties. It simplifies complex operational workflows and ensures a seamless experience in a cross-functional environment. With features like adding, modifying, or removing doctors, specialties, and departments, you have the flexibility to manage bookings efficiently. Schedule doctor slots in advance based on their availability, specific days, or holidays, ensuring an organized appointment booking process.</p>
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <img
              src={appointmentsmanagement}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Appointments Management"
            />
  </div>
  </div>
  <div className="sec-space"></div>
  </div>
  <div className="spacex" style={{
    backgroundImage:
      "linear-gradient(to right, rgba(var(--tcrgb), .10), rgba(var(--tcrgb), .10))"
  }}>
  <div className="sec-space"></div>
  <div className="row">
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <img
              src={datamanagement}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Data Management"
            />
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <h4 class="font-weight-bold text-gradient">Multiple Locations, One Application</h4>
  <p>Get the perfect solution to manage your hospital or clinic operations across multiple locations or cities—all from a single application. No need to juggle multiple accounts or platforms. This tool offers centralized management, allowing you to control everything from one account. It also provides the flexibility to schedule appointment slots across different locations based on doctors’ visit schedules, ensuring seamless coordination and efficient patient care.</p>
  </div>
  </div>
  <div className="sec-space"></div>
  </div>
  <div className="spacex" >
      <div className="sec-space"></div>
  <div className="row">
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <h4 class="font-weight-bold text-gradient">Flexible User & Role Management for Seamless Operations</h4>
  <p>Easily set up users and assign roles to streamline both front-end and back-end hospital operations. Adding new users, updating roles, or remove users is highly convenient. The tool provides a comprehensive role assignment system which covers front office staff, administrators, doctors and hospital management, ensuring proper hierarchy, security and privacy . This dynamic feature not only boosts team productivity but also promote	 transparency and flexibility by clearly defining every stage of the process.</p>
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <img
              src={rolemanagement}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Role Management"
            />
  </div>
  <div className="sec-space"></div>
</div>
<div className="sec-space"></div>
		</div>



    <div className="spacex" style={{
    backgroundImage:
      "linear-gradient(to right, rgba(var(--pcrgb), .10), rgba(var(--pcrgb), .10))"
  }}>  
     <div className="sec-space"></div>
		 <div className="row">
     <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
     <img
              src={reportmanagement}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Report Management"
            />
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">

       <h4 class="font-weight-bold text-gradient">Smart Reports & Data Management</h4>
        <p>Quickly generate and download comprehensive reports in Excel, CSV, or PDF formats with a single click. Customize your reports using advanced filters like doctor name, hospital branch, specialty, or location for precise insights. The system supports easy data management and ensures security by integrating with your existing CRM or HIS tools. With efficient data handling, you can make informed decisions and streamline hospital operations effortlessly.</p>
    </div>
    </div>
    <div className="sec-space"></div>
    </div>






    <div style={{ backgroundImage: `url(${testimonialsbg})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat' }}>
 <div
  className="blur-backdrop"
  style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--scrgb), .55), rgba(var(--tcrgb), .55)), url("")' }}
>

<div className="spacex">
  <div className="sec-space"></div>
      <h1 className="text-center font-weight-bold wc">Client Testimonials</h1>
	  <Slider {...settings}>

    {testimonialsData.map((testimonial, index) => (
              <div className="">
              <div className="my-4 mx-4 h-100">
              <div className="p-0 shadow bg-white rounded-large h-100">
                <div className="p-3">
                <div className="d-flex mt-3">
                    <div className="mr-3"><i className="fa fa-user fa-2x p-4 tc mr-1 rounded-large bc border" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--bcrgb), .05), rgba(var(--bcrgb), .05)), url("")' }}/></div>
                    <div><p className="mb-0 font-weight-bold">
                  {testimonial.name}
                  </p>
                  <p className="mb-0">{testimonial.company}</p>
                  </div>
                  </div>
                  <p className="mt-4">
                  {testimonial.description}
                  </p>
                </div>
              </div>
            </div>
            </div>

              ))}

  


      </Slider>
	 <div className="sec-space" />
	 </div> 
   </div>
   </div>





		 <div className="spacex" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--pcrgb), .0), rgba(var(--scrgb), .0)), url("")', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}>
      <div className="sec-space"></div>
          <h1 className="title text-center font-weight-bold text-gradient">Frequently Asked Questions</h1>
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 mb-4 faqs">
            <div className="heading text-center pb-4 wow slideInLeft">
              <h3 className="text-center font-weight-bold mb-2 bc"></h3>
            </div>
            <div className="wow fadeInUp">
              {faqData.map((faq, index) => (
                <div key={index} className="shadow faqheader mb-4 bg-white rounded-large wow slideInUp">
                  <div className="question bc" onClick={() => toggleAnswer(index)}>
                    {faq.question}
                  </div>
                  <div className="answercont" style={{ maxHeight: activeIndex === index ? 'none' : 0, overflow: 'hidden', transition: 'max-height 0.3s ease-in-out' }}>
                    <div className="answer">
                      <p className="mb-0">{faq.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
      </div>
      <div className="sec-space"></div>
    </div>
		 <Footer />
     </div>
   )
 }
 
  export default DigiHealthcareAppointmentSystem;
