 import React from 'react'
 import Navbar from './navbar'
 import Footer from './footer'
 import { Link } from "react-router-dom";

 const Pricing = () => {
  return (
    <div>
        <Navbar />
        <div className="sec-space"></div>
        <div className="spacex">
        <h1 className="font-weight-bold text-center text-gradient">Pricing</h1>
    <p className="text-center">30-Day Risk-Free Money Back Guarantee. Cancel Anytime.</p>
    <div className="row justify-content-md-center inlinetabs mt-4">
   <div className="col-auto">
     <div
       className="br-circle"
       style={{ backgroundColor: "rgba(var(--tcrgb),0.20)" }}
     >
       <ul
         className="nav nav-pills mb-3 justify-content-center"
         id="pills-tab"
         role="tablist"
       >
         <li className="nav-item" role="presentation">
           {" "}
           <a
             className="nav-link font-weight-bold active"
             id="pills-tab1-tab"
             data-toggle="pill"
             href="#pills-tab1"
             role="tab"
             aria-controls="pills-tab1"
             aria-selected="false"
           >
             Lead Management System
           </a>{" "}
         </li>
         <li className="nav-item" role="presentation">
           {" "}
           <a
             className="nav-link font-weight-bold"
             id="pills-tab2-tab"
             data-toggle="pill"
             href="#pills-tab2"
             role="tab"
             aria-controls="pills-tab2"
             aria-selected="true"
           >
             Healthcare Appointment System
           </a>{" "}
         </li>
       </ul>
     </div>
   </div>
 </div>
 <div className="tab-content" id="pills-tabContent">
   <div
     className="tab-pane pt-2 active"
     id="pills-tab1"
     role="tabpanel"
     aria-labelledby="pills-tab1-tab"
   >
     <div className="row">
 <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-4">
   <div className="shadow rounded-large borderd p-4 h-100">
     <h5 className="font-weight-bold">Free Trial for LMS</h5>
   <h1 className="font-weight-bold">₹ 0</h1>
   <p>Test drive DLMS FREE for 30 days! Build your course, generate leads.</p>
	  <ul>
	  <li>Engage with your audience</li>
		<li>Drive sales</li>
		<li>Join a vibrant community</li>
	  <li>Engage with your audience</li>
		<li>Drive sales</li>
		<li>Join a vibrant community</li>
	  </ul>
   <a className="button pbtn text-white font-weight-bold" href="">Select Plan</a>
   </div>
 </div>
 <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-4">
   <div className="shadow rounded-large borderd p-4 h-100">
   <h5 className="font-weight-bold">Starter for LMS</h5>
   <h1 className="font-weight-bold">₹ 1499</h1>
   <p>Test drive DLMS FREE for 30 days! Build your course, generate leads.</p>
	  <ul>
	  <li>Engage with your audience</li>
		<li>Drive sales</li>
		<li>Join a vibrant community</li>
	  <li>Engage with your audience</li>
		<li>Drive sales</li>
		<li>Join a vibrant community</li>
	  </ul>
   <a className="button pbtn text-white font-weight-bold" href="">Select Plan</a>
   </div>
 </div>
 <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-4">
   <div className="shadow rounded-large borderd p-4 h-100">
   <h5 className="font-weight-bold">Professional for LMS</h5>
   <h1 className="font-weight-bold">₹ 2999</h1>
   <p>Test drive DLMS FREE for 30 days! Build your course, generate leads.</p>
	  <ul>
	  <li>Engage with your audience</li>
		<li>Drive sales</li>
		<li>Join a vibrant community</li>
	  <li>Engage with your audience</li>
		<li>Drive sales</li>
		<li>Join a vibrant community</li>
	  </ul>
   <a className="button pbtn text-white font-weight-bold" href="">Select Plan</a>
   </div>
 </div>
</div>
   </div>
   <div
     className="tab-pane pt-2"
     id="pills-tab2"
     role="tabpane2"
     aria-labelledby="pills-tab2-tab"
   >
     <div className="row">
 <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-4">
   <div className="shadow rounded-large borderd p-4 h-100">
     <h5 className="font-weight-bold">Free Trial for HAS</h5>
   <h1 className="font-weight-bold">₹ 0</h1>
   <p>Test drive DHAS FREE for 30 days! Build your course, generate leads.</p>
<ul>
<li>Engage with your audience</li>
<li>Drive sales</li>
<li>Join a vibrant community</li>
<li>Engage with your audience</li>
<li>Drive sales</li>
<li>Join a vibrant community</li>
</ul>
   <a className="button pbtn text-white font-weight-bold" href="">Select Plan</a>
   </div>
 </div>
 <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-4">
   <div className="shadow rounded-large borderd p-4 h-100">
   <h5 className="font-weight-bold">Starter for HAS</h5>
   <h1 className="font-weight-bold">₹ 999</h1>
   <p>Test drive DHAS FREE for 30 days! Build your course, generate leads.</p>
<ul>
<li>Engage with your audience</li>
<li>Drive sales</li>
<li>Join a vibrant community</li>
<li>Engage with your audience</li>
<li>Drive sales</li>
<li>Join a vibrant community</li>
</ul>
   <a className="button pbtn text-white font-weight-bold" href="">Select Plan</a>
   </div>
 </div>
 <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-4">
   <div className="shadow rounded-large borderd p-4 h-100">
   <h5 className="font-weight-bold">Professional for HAS</h5>
   <h1 className="font-weight-bold">₹ 1999</h1>
   <p>Test drive DHAS FREE for 30 days! Build your course, generate leads.</p>
<ul>
<li>Engage with your audience</li>
<li>Drive sales</li>
<li>Join a vibrant community</li>
<li>Engage with your audience</li>
<li>Drive sales</li>
<li>Join a vibrant community</li>
</ul>
   <a className="button pbtn text-white font-weight-bold" href="">Select Plan</a>
   </div>
 </div>
</div>
   </div>
 </div>
    
   </div>
   <div className="sec-space"></div>
    <Footer />
    </div>
  )
}

export default Pricing
