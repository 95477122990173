import React, { useState } from 'react';
 import Navbar from './navbar'
 import Footer from './footer'
 import bg1 from '../images/bg-1.jpg'
 import bg2 from '../images/bg-2.jpg'


 const PrivacyPolicy = () => {


   return (
     <div>
         <Navbar />
         <div className="spacex" style={{
    backgroundImage:
      "linear-gradient(to right, rgba(var(--scrgb), .0), rgba(var(--scrgb), .0))"
  }}>  
  <div className="sec-space"></div>
      <h1 className="font-weight-bold">Privacy Policy</h1>
	  <div className="sec-space"></div>
      <p>
        At DigiLead Studios (Product of Digilitics Consulting Services LLP), we value your privacy and are committed to protecting your personal information. This Privacy Policy explains what information we collect, how we use it, and the choices you have regarding your information.
      </p>

      <h2 className="font-weight-bold">1. Information We Collect</h2>
      <p>We collect the following types of information:</p>
      <ul>
        <li><strong>Personal Information:</strong> Name, email address, phone number, billing information, and other details provided by you during registration.</li>
        <li><strong>Usage Information:</strong> Information about how you interact with our Service, including IP address, browser type, and pages visited.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies to enhance your experience, gather analytics, and improve our Service.</li>
      </ul>

      <h2 className="font-weight-bold">2. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide, maintain, and improve our Service.</li>
        <li>Process transactions and manage your account.</li>
        <li>Communicate with you about updates, promotions, and customer support.</li>
        <li>Ensure compliance with legal obligations.</li>
      </ul>

      <h2 className="font-weight-bold">3. Data Sharing</h2>
      <p>We do not sell your personal information to third parties. However, we may share your information with:</p>
      <ul>
        <li><strong>Service Providers:</strong> Third-party vendors who assist us in operating our Service.</li>
        <li><strong>Legal Authorities:</strong> When required by law or in response to legal processes.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred.</li>
      </ul>

      <h2 className="font-weight-bold">4. Data Security</h2>
      <p>
        We implement industry-standard security measures to protect your data from unauthorized access, alteration, or destruction. These measures include encryption, firewalls, and secure access controls.
        However, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.
      </p>

      <h2 className="font-weight-bold">5. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access the personal information we hold about you.</li>
        <li>Request corrections or updates to your personal information.</li>
        <li>Request the deletion of your personal information.</li>
      </ul>
      <p>To exercise your rights, please contact us at <a href="mailto:legal@digilitics.in" className="sc">legal@digilitics.in</a></p>

      <h2 className="font-weight-bold">6. Third-Party Links</h2>
      <p>Our Service may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to read the privacy policies of any third-party sites you visit.</p>

      <h2 className="font-weight-bold">7. Children's Privacy</h2>
      <p>
        Our Service is not directed to children under the age of 18. We do not knowingly collect personal information from children. If we become aware that a child has provided us with personal information, we will take steps to delete it.
      </p>

      <h2 className="font-weight-bold">8. Changes to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify users of significant changes by posting the updated policy on our website. Your continued use of the Service after such changes constitutes acceptance of the new policy.
      </p>

      <h2 className="font-weight-bold">9. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
      <p>
        Digilitics Consulting Services LLP<br />
        Hyderabad, Telangana, India<br />
        <a href="mailto:digitleadstudio@digilitics.in" className="sc">digitleadstudio@digilitics.in</a><br />
        <a href="tel:812 102 6060" className="sc">+91 812 102 6060</a>
      </p>
	  <div className="sec-space"></div>
    </div>
		 <Footer />
     </div>
   )
 }

 export default PrivacyPolicy