import React, { useState } from 'react'
 import Navbar from './navbar'
 import Footer from './footer'
 import { Link } from "react-router-dom";

 import dlmsbanner from '../images/homebanners/dlms-banner.png'
 import hasbanner from '../images/homebanners/has-banner.png'

 import sliderbg from '../images/slider-bg.jpg'
 import cta from '../images/cta.png'
 import testimonialsbg from '../images/testimonials-bg.jpg'
 import bg1 from '../images/bg-1.jpg'
 import bg2 from '../images/bg-2.jpg'

 

 import dlmsdashboard from '../images/dlms/dlms-dashboard.png'

 import dlmsfeature1 from '../images/dlms/features/dlms-feature-1.png'
 import dlmsfeature2 from '../images/dlms/features/dlms-feature-2.png'
 import dlmsfeature3 from '../images/dlms/features/dlms-feature-3.png'
 import dlmsfeature4 from '../images/dlms/features/dlms-feature-4.png'

 import hospitals from '../images/dlms/business/hospitals.png'
 import doctors from '../images/dlms/business/doctors.png'
 import clinics from '../images/dlms/business/clinics.png'
 import dental from '../images/dlms/business/dental.png'

 import hasdashboard from '../images/has/has-dashboard.png'

 import hasfeature1 from '../images/has/features/has-feature-1.png'
 import hasfeature2 from '../images/has/features/has-feature-2.png'
 import hasfeature3 from '../images/has/features/has-feature-3.png'
 import hasfeature4 from '../images/has/features/has-feature-4.png'

 import realestate from '../images/has/business/real-estate.png'
 import financialadvisors from '../images/has/business/financial-advisors.png'
 import businesses from '../images/has/business/businesses.png'
 import salesteams from '../images/has/business/sales-teams.png'



 import Slider from "react-slick";
 import "slick-carousel/slick/slick.css"
 import "slick-carousel/slick/slick-theme.css"


 const Home = () => {

const settings = {
    dots: true,    
    infinite: true, 
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };
  
const [activeIndex, setActiveIndex] = useState(null);

const testimonialsData = [
  {
    name: "John Smith",
    company: "Sales Manager, Tech Innovators Inc.",
    description: "DLMS has transformed the way we handle leads. The automated follow-ups and real-time tracking ensure no opportunities are missed. Our sales team is more efficient, and we've seen a noticeable increase in conversions."
  },
  {
    name: "Emily Johnson",
    company: "Marketing Director, GreenWave Solutions",
    description: "We were struggling to keep track of leads across various channels, but DLMS has simplified everything. It integrates seamlessly with our CRM and provides in-depth analytics that help us make smarter marketing decisions."
  },
  {
    name: "Michael Davis",
    company: "VP of Sales, BlueSky Enterprises",
    description: "DLMS has made managing our sales pipeline so much easier. The custom lead scoring system has allowed us to prioritize high-value prospects, which has significantly boosted our team's performance and revenue."
  },
  {
    name: "Sarah Williams",
    company: "CEO, EcoTech Solutions",
    description: "As a fast-growing company, we needed a lead management solution that could scale with us. DLMS has been incredibly flexible, allowing us to adapt our approach as we evolve. It's been a game-changer for our team."
  },
  {
    name: "Daniel Brown",
    company: "Sales Operations Manager, Apex Consulting",
    description: "DLMS has streamlined our lead generation and follow-up processes. The software is intuitive, and the automation has saved us hours each week. We’ve seen our conversion rates improve dramatically since we implemented it."
  },
  {
    name: "Dr. Linda Evans",
    company: "Medical Director, Family Care Clinic",
    description: "The DHAS Appointment System has completely transformed how we manage patient bookings. It's user-friendly, reduces wait times, and has improved both patient satisfaction and staff efficiency."
  },
  {
    name: "James Williams",
    company: "Operations Manager, Sunrise Health Center",
    description: "Since implementing DHAS, our appointment scheduling process has become so much smoother. The automated reminders help reduce no-shows, and the system's integration with our EMR is seamless."
  },
  {
    name: "Dr. Sarah Thompson",
    company: "Orthopedic Surgeon, Premier Orthopedics",
    description: "DHAS Scheduler has simplified our appointment management. The ability to see real-time availability, patient preferences, and quickly reschedule appointments has enhanced the experience for both patients and our staff."
  },
  {
    name: "Rachel Green",
    company: "Patient Services Coordinator, Greenleaf Medical Group",
    description: "With DHAS, we’ve seen a dramatic reduction in scheduling errors. The software’s intuitive interface allows us to manage appointments across multiple locations, improving our overall workflow and patient experience."
  },
  {
    name: "Dr. Mark Johnson",
    company: "Chief of Staff, HealthPlus Family Practice",
    description: "The Digi Healthcare Appointment System is a game-changer. It's reduced administrative time significantly, allowing our medical staff to focus more on patient care. The real-time updates and online booking options have been a big hit with our patients."
  }
];

  const faqData = [
    {
      question: "What is digi lead management software?",
      answer: "Digi Lead management software is a tool designed to help businesses manage, track, and nurture leads throughout the sales pipeline. It allows companies to capture leads, assign them to sales representatives, track interactions, and automate follow-ups, ensuring no opportunity is missed."
    },
    {
      question: "How does digi lead management software help improve sales efficiency?",
      answer: "Digi Lead management software streamlines the sales process by organizing leads, automating follow-up tasks, and providing insights into lead behavior. It helps sales teams prioritize high-potential leads, reduces manual efforts, and accelerates the lead conversion process, ultimately improving sales efficiency."
    },
    {
      question: "Can digi lead management software integrate with other tools I’m using?",
      answer: "Yes, most digi lead management software can integrate with a wide range of other business tools, such as CRM systems, email marketing platforms, customer support software, and analytics tools. This integration ensures a seamless flow of data and helps create a more unified approach to lead generation and nurturing."
    },
    {
      question: "Is digi lead management software suitable for small businesses?",
      answer: "Absolutely! Digi Lead management software is suitable for businesses of all sizes, including small businesses. Many software solutions offer scalable plans with essential features, making them accessible to small businesses looking to streamline their lead management process without a large investment."
    },
    {
      question: "How do I measure the effectiveness of digi lead management software?",
      answer: "The effectiveness of digi lead management software can be measured by tracking key metrics such as lead conversion rate, sales cycle length, response time, and customer retention. Additionally, the software's ability to improve lead qualification and reduce manual tasks can also serve as indicators of its effectiveness."
    },
    {
      question: "What is a Healthcare Appointment System?",
      answer: "A Digi Healthcare Appointment System is a digital tool designed to manage and streamline the process of booking, scheduling, and tracking medical appointments. It allows patients to book appointments online, receive reminders, and communicate with healthcare providers, while enabling clinics or hospitals to efficiently manage their schedules and optimize patient flow."
    },
    {
      question: "How does a Digi Healthcare Appointment System benefit patients?",
      answer: "A Digi Healthcare Appointment System provides patients with the convenience of booking appointments online, anytime and anywhere. It reduces wait times by allowing patients to choose available slots, send reminders for upcoming appointments, and enable easy rescheduling or cancellations. This enhances patient satisfaction and helps them manage their healthcare needs efficiently."
    },
    {
      question: "Can the Digi Healthcare Appointment System integrate with other healthcare systems?",
      answer: "Yes, most digi lead management software can integrate with a wide range of other business tools, such as CRM systems, email marketing platforms, customer support software, and analytics tools. This integration ensures a seamless flow of data and helps create a more unified approach to lead generation and nurturing."
    },
    {
      question: "How do I measure the effectiveness of digi lead management software?",
      answer: "The effectiveness of digi lead management software can be measured by tracking key metrics such as lead conversion rate, sales cycle length, response time, and customer retention. Additionally, the software's ability to improve lead qualification and reduce manual tasks can also serve as indicators of its effectiveness."
    },
    {
      question: "Can I customize the Digi Healthcare Appointment System to fit my practice’s needs?",
      answer: "Yes, most Digi Healthcare Appointment Systems offer customization options to cater to the unique needs of your practice. You can configure features such as appointment types, duration, availability, and patient notifications. This flexibility allows the system to adapt to various specialties and practice sizes, ensuring a tailored experience for both providers and patients."
    }
  ];

  const toggleAnswer = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  
   return (
     <div>
         <Navbar />
         <div style={{ backgroundImage: `url(${sliderbg})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat' }}>
 <div
  className="blur-backdrop"
  style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--scrgb), .65), rgba(var(--tcrgb), .65)), url("")' }}
>
		 {/*</div><div className="spacex" style={{
    backgroundImage:
      "linear-gradient(to right, rgba(var(--bcrgb), .05), rgba(var(--bcrgb), .05))"
  }}>*/}
<div className="spacex">
  <div id="carouselExampleIndicators" className="homeslider carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="">
          <div className="sec-space"></div>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <h1 className="wc font-weight-bold">
            Digi Lead Management System</h1>
            <p className="wc">Simple, Smart, and Stress-free lead management for growing your business.</p>
            <p className="wc">Track leads effortlessly—NO technical skills required!</p>
			{/*<Link to="/lead-management-system" className="button wbtn">Read More</Link>*/}
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <img
              src={dlmsbanner}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Lorem Ipsum"
            />
          </div>
        </div>
        <div className="sec-space"></div>
      </div>
        </div>
        <div className="carousel-item">
          <div className="">
          <div className="sec-space"></div>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <h1 className="wc font-weight-bold">
            Digi Healthcare Appointment System</h1>
            <p className="wc">
            Simplify scheduling, enhance patient care, and boost doctor efficiency—all in one seamless solution - DHAS
            </p>
			{/*<Link to="/healthcare-appointment-system" className="button wbtn">Read More</Link>*/}
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <img
              src={hasbanner}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Lorem Ipsum"
            />
          </div>

        </div>
        <div className="sec-space"></div>
      </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
	




    <div className="spacex" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--pcrgb), .0), rgba(var(--scrgb), .0)), url("")', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}>
<div className="sec-space"></div>
<h1 className="text-center font-weight-bold text-gradient">Digi Lead Management System</h1>
<p className="text-center">DLMS is a simple and easy-to-use Lead Management Tool that is also comprehensive in terms of its features & usability factors. It was design for small and medium-sized businesses that can’t invest on big CRM or struggle with complicated tools and their complex flows. User do not need any technical knowledge or training to operate DLMS, making it effortless to track lead flows from multiple channels, including direct inbound call leads.
</p>
<div className="sec-space"></div>
<div className="row mb-4">
<div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <img
              src={dlmsdashboard}
              className="img-fluid bg-white rounded-large p-4 border mb-4"
              alt="Lorem Ipsum"
            />
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">


  <div className="row">
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
    <div className="p-3 rounded-large h-100" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--pcrgb), .05), rgba(var(--pcrgb), .05)), url("")', border:'1px solid var(--pc)' }}>
  <center><img
              src={dlmsfeature1}
              className=""
              alt="Lead Capture and Tracking" width="55px"
            /></center>
  <p className="text-center mt-3 mb-0 pc">Lead Capture<br />and Tracking</p>
  </div>
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <div className="p-3 rounded-large h-100" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--scrgb), .05), rgba(var(--scrgb), .05)), url("")', border:'1px solid var(--sc)' }}>
  <center><img
              src={dlmsfeature2}
              className=""
              alt="Multi-Channel
Support"  width="55px"
            /></center>
  <p className="text-center mt-3 mb-0 sc">Multi-Channel
  <br />Support</p>
  </div>
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <div className="p-3 rounded-large h-100" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--tcrgb), .05), rgba(var(--tcrgb), .05)), url("")', border:'1px solid var(--tc)' }}>
  <center><img
              src={dlmsfeature3}
              className=""
              alt="Comprehensive Dashboards"  width="55px"
            /></center>
  <p className="text-center mt-3 mb-0 tc">Comprehensive<br />Dashboards</p>
  </div>
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <div className="p-3 rounded-large h-100" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--pcrgb), .05), rgba(var(--pcrgb), .05)), url("")', border:'1px solid var(--pc)' }}>
  <center><img
              src={dlmsfeature4}
              className=""
              alt="Easy to Use"  width="55px"
            /></center>
  <p className="text-center mt-3 mb-0 pc">Easy to<br />Use</p>
  </div>
  </div>
  </div>
</div>


  <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 mt-2 mb-4">
  <h4 className="font-weight-bold text-center text-gradient">Who will use Digi Lead Management System</h4>
  </div>
  <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 col-xs-12 mb-4">
  <div className="border rounded-large border bg-white p-4">
  <center><img
              src={realestate}
              className="img-fluid"
              alt="Lorem Ipsum"
            /></center>
  <p className="text-center mt-3 mb-0 sc font-weight-600">Real Estate  </p></div>
  </div>
  <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 col-xs-12 mb-4">
  <div className="border rounded-large border bg-white p-4">
  <center><img
              src={financialadvisors}
              className="img-fluid"
              alt="Lorem Ipsum"
            /></center>
  <p className="text-center mt-3 mb-0 sc font-weight-600">Financial Advisors  </p></div>
  </div>
  <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 col-xs-12 mb-4">
  <div className="border rounded-large border bg-white p-4">
  <center><img
              src={businesses}
              className="img-fluid"
              alt="Lorem Ipsum"
            /></center>
  <p className="text-center mt-3 mb-0 sc font-weight-600">Businesses</p></div>
  </div>
  <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 col-xs-12 mb-4">
  <div className="border rounded-large border bg-white p-4">
  <center><img
              src={salesteams}
              className="img-fluid"
              alt="Lorem Ipsum"
            /></center>
  <p className="text-center mt-3 mb-0 sc font-weight-600">Sales Teams  </p></div>
  </div>
</div>
<center>
<Link to="/lead-management-system" className="button pbtn text-white">Read More</Link>
</center>
<div className="sec-space"></div>
</div>




<div style={{ backgroundImage: `url(${bg1})`, backgroundSize: 'cover', backgroundPosition: 'center center',  backgroundRepeat: 'no-repeat' }}>
<div
  className="blur-backdrop"
  style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--wcrgb), .65), rgba(var(--wcrgb), .65)), url("")' }}
>
<div className="spacex" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--pcrgb), .0), rgba(var(--scrgb), .0)), url("")', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}>
<div className="sec-space"></div>
<div className="row justify-content-center mb-4">
<div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
<h1 className="text-center font-weight-bold text-gradient">Easy to Manage your Marketing leads!</h1>
<p className="text-center">Explore how effortlessly you can connect all your marketing channels with our DLMS and effectively manage every lead. It helps to enhance your efficiency and improve ROI from marketing actives.</p>
<center>
<Link to="/book-a-demo" className="button pbtn text-white">Book a Demo</Link>
</center>
</div>
<div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 col-xs-12 mb-4">
  <center><img
              src={cta}
              className="img-fluid"
              alt="Lorem Ipsum"
            /></center>
</div>
</div>

<div className="sec-space"></div>
</div>
</div>
</div>






<div className="spacex" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--bcrgb), .0), rgba(var(--bcrgb), .0)), url("")', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}>
<div className="sec-space"></div>
<h1 className="text-center font-weight-bold text-gradient">Digi Healthcare Appointment System
</h1>
<p className="text-center">DHAS is most effective tool for streamlining hospital or clinic operations by efficiently scheduling every patient appointment based on each doctor’s availability. It offers a highly customizable appointment booking system with re-scheduling and cancellation options at a click of button. It reduces time wastage, enhance patient management and boost doctors’ efficiency and productivity through seamless integration with HIS
</p>
<div className="sec-space"></div>
<div className="row mb-4">
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">


  <div className="row">
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <div className="p-3 rounded-large h-100" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--pcrgb), .05), rgba(var(--pcrgb), .05)), url("")', border:'1px solid var(--pc)' }}>
  <center><img
              src={hasfeature1}
              className=""
              alt="Dynamic Appointment Booking" width="55px"
            /></center>
  <p className="text-center mt-3 mb-0 pc">Dynamic Appointment
  <br />Booking</p>
  </div>
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <div className="p-3 rounded-large h-100" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--scrgb), .05), rgba(var(--scrgb), .05)), url("")', border:'1px solid var(--sc)' }}>
  <center><img
              src={hasfeature2}
              className=""
              alt="Automated Reminders" width="55px"
            /></center>
  <p className="text-center mt-3 mb-0 sc">Automated<br />Reminders</p>
  </div>
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <div className="p-3 rounded-large h-100" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--tcrgb), .05), rgba(var(--tcrgb), .05)), url("")', border:'1px solid var(--tc)' }}>
  <center><img
              src={hasfeature3}
              className=""
              alt="Doctor Avaiability Management" width="55px"
            /></center>
  <p className="text-center mt-3 mb-0 tc">Doctor Avaiability<br />Management</p>
  </div>
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <div className="p-3 rounded-large h-100" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--pcrgb), .05), rgba(var(--pcrgb), .05)), url("")', border:'1px solid var(--pc)' }}>
  <center><img
              src={hasfeature4}
              className="" width="55px"
              alt="Easy to connect HIS
(Hospital Information System)"
            /></center>
  <p className="text-center mt-3 mb-0 pc">Easy to connect<br />Hospital Information System</p>
  </div>
  </div>
  </div>


  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <center><img
              src={hasdashboard}
              className="img-fluid bg-white rounded-large p-4 border mb-4"
              alt="Lorem Ipsum"
            /></center>
  </div>
  <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 mt-2 mb-4">
  <h4 className="font-weight-bold text-center text-gradient">Who will use Digi Healthcare Appointment System</h4>
  </div>
  <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 col-xs-12 mb-4">
  <div className="border rounded-large border bg-white p-4">
  <center><img
              src={hospitals}
              className="img-fluid"
              alt="Lorem Ipsum"
            /></center>
  <p className="text-center mt-3 mb-0 sc font-weight-600">Hospital</p></div>
  </div>
  <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 col-xs-12 mb-4">
  <div className="border rounded-large border bg-white p-4">
  <center><img
              src={doctors}
              className="img-fluid"
              alt="Lorem Ipsum"
            /></center>
  <p className="text-center mt-3 mb-0 sc font-weight-600">Doctors</p></div>
  </div>
  <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 col-xs-12 mb-4">
  <div className="border rounded-large border bg-white p-4">
  <center><img
              src={clinics}
              className="img-fluid"
              alt="Lorem Ipsum"
            /></center>
  <p className="text-center mt-3 mb-0 sc font-weight-600">Clinics</p></div>
  </div>
  <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 col-xs-12 mb-4">
  <div className="border rounded-large border bg-white p-4">
  <center><img
              src={dental}
              className="img-fluid"
              alt="Lorem Ipsum"
            /></center>
  <p className="text-center mt-3 mb-0 sc font-weight-600">Dental</p></div>
  </div>
</div>
<center><Link to="/healthcare-appointment-system" className="button pbtn text-white">Read More</Link></center>
<div className="sec-space"></div>
</div>









	<div style={{ backgroundImage: `url(${testimonialsbg})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat' }}>
 <div
  className="blur-backdrop"
  style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--scrgb), .55), rgba(var(--tcrgb), .55)), url("")' }}
>

<div className="spacex">
  <div className="sec-space"></div>
      <h1 className="text-center font-weight-bold wc">Client Testimonials</h1>
	  <Slider {...settings}>

    {testimonialsData.map((testimonial, index) => (
              <div className="">
              <div className="my-4 mx-4 h-100">
              <div className="p-0 border bg-white rounded-large h-100">
                <div className="p-3">
                  <div className="d-flex mt-3">
                    <div className="mr-3"><i className="fa fa-user fa-2x p-4 tc mr-1 rounded-large bc border" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--bcrgb), .05), rgba(var(--bcrgb), .05)), url("")' }}/></div>
                    <div><p className="mb-0 font-weight-bold">
                  {testimonial.name}
                  </p>
                  <p className="mb-0">{testimonial.company}</p>
                  </div>
                  </div>
                  <p className="mt-4">
                  {testimonial.description}
                  </p>
                </div>
              </div>
            </div>
            </div>

              ))}

  


      </Slider>
	 <div className="sec-space" />
	 </div> 
   </div>
   </div>





<div className="spacex" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--scrgb), .0), rgba(var(--tcrgb), .0)), url("")', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}>
<div className="sec-space"></div>
          <h1 className="title text-center font-weight-bold text-gradient">Frequently Asked Questions</h1>
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 mb-4 faqs">
            <div className="heading text-center pb-4 wow slideInLeft">
              <h3 className="text-center font-weight-bold mb-2 bc"></h3>
            </div>
            <div className="wow fadeInUp">
              {faqData.map((faq, index) => (
                <div key={index} className="shadow faqheader mb-4 bg-white rounded-large wow slideInUp">
                  <div className="question bc" onClick={() => toggleAnswer(index)}>
                    {faq.question}
                  </div>
                  <div className="answercont" style={{ maxHeight: activeIndex === index ? 'none' : 0, overflow: 'hidden', transition: 'max-height 0.3s ease-in-out' }}>
                    <div className="answer">
                      <p className="mb-0">{faq.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
      </div>
      <div className="sec-space"></div>
    </div>




		 <Footer />
     </div>
   )
 }
 
 export default Home;
