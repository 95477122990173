import React, { useState } from 'react';
 import Navbar from './navbar'
 import Footer from './footer'
 import bg1 from '../images/bg-1.jpg'
 import bg2 from '../images/bg-2.jpg'


 const BookADemo = () => {


   return (
     <div>
         <Navbar />
         <div style={{ backgroundImage: `url(${bg2})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat' }}>
 <div
  className="blur-backdrop"
  style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--wcrgb), .65), rgba(var(--wcrgb), .65)), url("")' }}
>
  <div  style={{
    backgroundImage:
      "linear-gradient(111deg, rgba(var(--scrgb), .10), rgba(var(--scrgb), .10))"
  }} >
    		 <div className="spacex">
    		 <div className="sec-space"></div>
         <div className="sec-space"></div>
		 <form className="form-elements" action="" method="POST">
     <div className="row">
     <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 mb-4">
     <h1 className="font-weight-bold text-gradient mb-4">Request a Callback</h1>
     <p className="">Need assistance? Our team is here to help!</p>
     <p>Simply leave your details below, and we'll get in touch with you at a time that’s most convenient for you.</p>
     <p>We look forward to resolving your queries quickly and efficiently.</p>
      </div>
     <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4">
  <div className="shadow rounded-large bg-white border p-4">
  <h4 className="font-weight-bold text-center mb-4">Request A Callback</h4>
  <div className="row">
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4">
      <input
        type="text"
        name="name"
        required=""
        className="w-100"
        placeholder="Name *"
      />
    </div>
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4">
      <input
        type="email"
        required=""
        name="email"
        className="w-100"
        placeholder="Email *"
      />
    </div>
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4">
      <input
        type="text"
        maxLength={10}
        required=""
        name="phone"
        className="w-100"
        placeholder="Mobile *"
      />
    </div>
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4">
      <input
        type="text"
        name="business_name"
        required=""
        className="w-100"
        placeholder="Business Name *"
      />
    </div>
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-0">
      <input
        type="submit"
        name="submit"
        className="button sbtn w-100"
        defaultValue="Submit"
      />
    </div>
    </div>
  </div>
  </div>
  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  </div>
  </div>
</form>
<div className="sec-space"></div>
</div>
</div>
</div>
</div>
		 <Footer />
     </div>
   )
 }

 export default BookADemo