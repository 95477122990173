import React, { useState } from 'react'
 import Navbar from './navbar'
 import Footer from './footer'
 import Slider from "react-slick";
 import "slick-carousel/slick/slick.css"
 import "slick-carousel/slick/slick-theme.css"

 import dlmsbanner1 from '../images/dlms/banners/dlms-banner-1.png'

 import campaignmanagement from '../images/dlms/modules/campaign-management.png'
 import contactmanagement from '../images/dlms/modules/contact-management.png'
 import contentmanagement from '../images/dlms/modules/content-management.png'
 import datamanagement from '../images/dlms/modules/data-management.png'
 import leadmanagement from '../images/dlms/modules/lead-management.png'
 import priceestimates from '../images/dlms/modules/price-estimates.png'
 import reportsmanagement from '../images/dlms/modules/reports-management.png'
 import rolemanagement from '../images/dlms/modules/role-management.png'
 import scheduleappointment from '../images/dlms/modules/schedule-appointment.png'
 import usermanagement from '../images/dlms/modules/user-management.png'

 import sliderbg from '../images/slider-bg.jpg'
 import testimonialsbg from '../images/testimonials-bg.jpg'
 import bg1 from '../images/bg-1.jpg'
 import bg2 from '../images/bg-2.jpg'

 const DigiLeadManagementSystem = () => {

  const settings = {
    dots: true, 
    infinite: true, 
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };

  const [activeIndex, setActiveIndex] = useState(null);
  
  const testimonialsData = [
    {
      name: "John Smith",
      company: "Sales Manager, Tech Innovators Inc.",
      description: "DLMS has transformed the way we handle leads. The automated follow-ups and real-time tracking ensure no opportunities are missed. Our sales team is more efficient, and we've seen a noticeable increase in conversions."
    },
    {
      name: "Emily Johnson",
      company: "Marketing Director, GreenWave Solutions",
      description: "We were struggling to keep track of leads across various channels, but DLMS has simplified everything. It integrates seamlessly with our CRM and provides in-depth analytics that help us make smarter marketing decisions."
    },
    {
      name: "Michael Davis",
      company: "VP of Sales, BlueSky Enterprises",
      description: "DLMS has made managing our sales pipeline so much easier. The custom lead scoring system has allowed us to prioritize high-value prospects, which has significantly boosted our team's performance and revenue."
    },
    {
      name: "Sarah Williams",
      company: "CEO, EcoTech Solutions",
      description: "As a fast-growing company, we needed a lead management solution that could scale with us. DLMS has been incredibly flexible, allowing us to adapt our approach as we evolve. It's been a game-changer for our team."
    },
    {
      name: "Daniel Brown",
      company: "Sales Operations Manager, Apex Consulting",
      description: "DLMS has streamlined our lead generation and follow-up processes. The software is intuitive, and the automation has saved us hours each week. We’ve seen our conversion rates improve dramatically since we implemented it."
    }
  ];
  
  const faqData = [
    {
      question: "What is digi lead management software?",
      answer: "Digi Lead management software is a tool designed to help businesses manage, track, and nurture leads throughout the sales pipeline. It allows companies to capture leads, assign them to sales representatives, track interactions, and automate follow-ups, ensuring no opportunity is missed."
    },
    {
      question: "How does digi lead management software help improve sales efficiency?",
      answer: "Digi Lead management software streamlines the sales process by organizing leads, automating follow-up tasks, and providing insights into lead behavior. It helps sales teams prioritize high-potential leads, reduces manual efforts, and accelerates the lead conversion process, ultimately improving sales efficiency."
    },
    {
      question: "Can digi lead management software integrate with other tools I’m using?",
      answer: "Yes, most digi lead management software can integrate with a wide range of other business tools, such as CRM systems, email marketing platforms, customer support software, and analytics tools. This integration ensures a seamless flow of data and helps create a more unified approach to lead generation and nurturing."
    },
    {
      question: "Is digi lead management software suitable for small businesses?",
      answer: "Absolutely! Digi Lead management software is suitable for businesses of all sizes, including small businesses. Many software solutions offer scalable plans with essential features, making them accessible to small businesses looking to streamline their lead management process without a large investment."
    },
    {
      question: "How do I measure the effectiveness of digi lead management software?",
      answer: "The effectiveness of digi lead management software can be measured by tracking key metrics such as lead conversion rate, sales cycle length, response time, and customer retention. Additionally, the software's ability to improve lead qualification and reduce manual tasks can also serve as indicators of its effectiveness."
    }
  ];
  
    const toggleAnswer = (index) => {
      if (index === activeIndex) {
        setActiveIndex(null);
      } else {
        setActiveIndex(index);
      }
    };

   return (
     <div>
         <Navbar />

         <div style={{ backgroundImage: `url(${sliderbg})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat' }}>
 <div
  className="blur-backdrop"
  style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--scrgb), .65), rgba(var(--tcrgb), .65)), url("")' }}
>
		 <div className="spacex">

  <div id="carouselExampleIndicators" className="homeslider carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="">
          <div className="sec-space"></div>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <div className="sec-space" />
            <h1 className="font-weight-bold text-white">
            Digi Lead Management System
            </h1>
            <p className="wc">Simple, Smart, and Stress-free lead management for growing your business.</p>
            <p className="wc">Track leads effortlessly—NO technical skills required!</p>
            <div className="sec-space" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <img
              src={dlmsbanner1}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Lorem Ipsum"
            />
          </div>
        </div>
        <div className="sec-space"></div>
      </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>






    <div className="spacex">
    <div className="sec-space"></div>
         <h1 className="font-weight-bold text-center text-gradient">Digi Lead Management System</h1>
         <p class="text-center">DLMS is a simple and easy-to-use Lead Management Tool that is also comprehensive in terms of its features & usability factors. It was design for small and medium-sized businesses that can’t invest on big CRM or struggle with complicated tools and their complex flows. User do not need any technical knowledge or training to operate DLMS, making it effortless to track lead flows from multiple channels, including direct inbound call leads.</p>
         <div className="sec-space"></div>
     </div> 



     <div className="spacex" style={{
    backgroundImage:
      "linear-gradient(to right, rgba(var(--pcrgb), .10), rgba(var(--pcrgb), .10))"
  }}>  
     <div className="sec-space"></div>
		 <div className="row">
     <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
     <img
              src={leadmanagement}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Lorem Ipsum"
            />
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">

       <h4 class="font-weight-bold text-gradient">Streamline Leads Management</h4>
      
        <p>Our LMS tool offers a simple and user-friendly interface for managing all your leads in one place. It provides detailed information about the each lead and its status. Most importantly, you can easily identify the source of each lead – whether it’s from website, Google Ads, Social Media, Third Party platform or direct incoming calls- without the need to toggle between multiple windows. This ensure that every lead is managed efficiently, and you can monitor the support team’s response time at every stage of the lead’s journey.</p>
    </div>
    </div>
    <div className="sec-space"></div>
    </div>
    <div className="spacex">
      <div className="sec-space"></div>
    <div className="row">
    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">

      <h4 class="font-weight-bold text-gradient">Define User & Role Management</h4>
      <p>It enables you to define the users based on your requirement and assign specific roles to manage different stages of the lead journey. Easily add new user or remove existing ones with just a few clicks. You can also update the roles in real time, ensuring seamless job re-assignments if someone is on leave dure to emergency. This dynamic feature helps you to track your team’s productivity and optimize the lead-to-business conversion process.</p>
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <img
              src={usermanagement}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Lorem Ipsum"
            />
  </div>
  </div>
  <div className="sec-space"></div>
  </div>
  <div className="spacex" style={{
    backgroundImage:
      "linear-gradient(to right, rgba(var(--tcrgb), .10), rgba(var(--tcrgb), .10))"
  }}>
  <div className="sec-space"></div>
  <div className="row">
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <img
              src={rolemanagement}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Lorem Ipsum"
            />
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <h4 class="font-weight-bold text-gradient">Simplified  Multiple-location Business Management</h4>
  <p>Effortlessly manage multiple business locations or units from a single platform. It offers a unique feature to define the your multiple locations or units within the same business or even manage multiple businesses with various locations. This ensures seamless lead distribution to appropriate location or business unit without any confusion. It also enables you to accurately the accurately track the performance of each business location, helping you to optimize your efforts and reduce the cost.</p>
  </div>
  </div>
  <div className="sec-space"></div>
  </div>
  <div className="spacex" >
      <div className="sec-space"></div>
  <div className="row">
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <h4 class="font-weight-bold text-gradient">Smart Contact Segmentation for Effective Nurturing</h4>
  <p>Organize your contacts into meaningful buckets based on their interactions with your contact centre and purchase behaviour. This allows you to nurture the potential customers for future business opportunities while keeping existing customers engage with updates on new launches and offers. By personalizing your communication, you can enhance customer loyalty and increase the lifetime value of each customer. </p>
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
  <img
              src={datamanagement}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Lorem Ipsum"
            />
  </div>
  <div className="sec-space"></div>
</div>
<div className="sec-space"></div>
		</div>




    <div className="spacex" style={{
    backgroundImage:
      "linear-gradient(to right, rgba(var(--pcrgb), .10), rgba(var(--pcrgb), .10))"
  }}>  
     <div className="sec-space"></div>
		 <div className="row">
     <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
     <img
              src={campaignmanagement}
              className="img-fluid bg-white rounded-large p-4 border"
              alt="Lorem Ipsum"
            />
  </div>
  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">

       <h4 class="font-weight-bold text-gradient">Leads and Revenue Prediction for Smarter Planning</h4>
        <p>Our forecasting application predicts the number leads you can generated within the selected period based on your desired budget. It helps you calculate ROI by analysing historic performance data from the respective lead platform. By considering all keys ads metrics, this tool offers precise, transparent predictions, empowering you to make  data driven decisions and achieve business outcomes.</p>
    </div>
    </div>
    <div className="sec-space"></div>
    </div>
  
  


    <div style={{ backgroundImage: `url(${testimonialsbg})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat' }}>
 <div
  className="blur-backdrop"
  style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--scrgb), .55), rgba(var(--tcrgb), .55)), url("")' }}
>

<div className="spacex">
  <div className="sec-space"></div>
      <h1 className="text-center font-weight-bold wc">Client Testimonials</h1>
	  <Slider {...settings}>

    {testimonialsData.map((testimonial, index) => (
              <div className="">
              <div className="my-4 mx-4 h-100">
              <div className="p-0 shadow bg-white rounded-large h-100">
                <div className="p-3">
                <div className="d-flex mt-3">
                    <div className="mr-3"><i className="fa fa-user fa-2x p-4 tc mr-1 rounded-large bc border" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--bcrgb), .05), rgba(var(--bcrgb), .05)), url("")' }}/></div>
                    <div><p className="mb-0 font-weight-bold">
                  {testimonial.name}
                  </p>
                  <p className="mb-0">{testimonial.company}</p>
                  </div>
                  </div>
                  <p className="mt-4">
                  {testimonial.description}
                  </p>
                </div>
              </div>
            </div>
            </div>

              ))}

  


      </Slider>
	 <div className="sec-space" />
	 </div> 
   </div>
   </div>



         <div className="spacex" style={{ backgroundImage: 'linear-gradient(to right, rgba(var(--pcrgb), .0), rgba(var(--scrgb), .0)), url("")', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}>
      <div className="sec-space"></div>
          <h1 className="title text-center font-weight-bold text-gradient">Frequently Asked Questions</h1>
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 mb-4 faqs">
            <div className="heading text-center pb-4 wow slideInLeft">
              <h3 className="text-center font-weight-bold mb-2 bc"></h3>
            </div>
            <div className="wow fadeInUp">
              {faqData.map((faq, index) => (
                <div key={index} className="shadow faqheader mb-4 bg-white rounded-large wow slideInUp">
                  <div className="question bc" onClick={() => toggleAnswer(index)}>
                    {faq.question}
                  </div>
                  <div className="answercont" style={{ maxHeight: activeIndex === index ? 'none' : 0, overflow: 'hidden', transition: 'max-height 0.3s ease-in-out' }}>
                    <div className="answer">
                      <p className="mb-0">{faq.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
      </div>
      <div className="sec-space"></div>
    </div>


		 <Footer />
     </div>
   )
 }
 
 export default DigiLeadManagementSystem;
