 import React from 'react'
 import Navbar from './navbar'
 import Footer from './footer'
 import whatsapp from '../images/integrations/whatsapp.png';
 import sendgrid from '../images/integrations/send-grid.png';
 import twilio from '../images/integrations/twilio.png';

 const Integrations = () => {
   return (
     <div>
         <Navbar />
         <div className="sec-space"></div>
         <div className="spacex">
         <h1 className="font-weight-bold text-center mb-3 text-gradient">Integrations</h1>
         <p class="text-center mb-5">In today’s interconnected world, seamless communication is crucial for businesses to maintain strong relationships with customers. Our suite of integrations includes powerful gateways for WhatsApp, Email, and SMS, allowing businesses to effortlessly engage with their audience through multiple channels.</p>
		 <div className="row">
  <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-4">
    <div className="shadow rounded-large border p-4 h-100">
      <img src={whatsapp} className="img-fluid rounded-large mb-4" style={{ backgroundImage: 'linear-gradient(to right, rgba(93,211,59, .10), rgba(93,211,59, .10)), url("")' }}/>
      <h5 className="font-weight-bold text-gradient">WhatsApp Gateways</h5>
      <p>WhatsApp gateways in our Lead Management System allow for direct engagement with leads via popular messaging platforms. They streamline communication, enabling real-time interactions and personalized marketing efforts to enhance customer relationships.</p>
    </div>
  </div>
  <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-4">
    <div className="shadow rounded-large border p-4 h-100">
      <img src={sendgrid} className="img-fluid rounded-large mb-4" style={{ backgroundImage: 'linear-gradient(to right, rgba(26,130,226, .10), rgba(26,130,226, .10)), url("")' }}/>
      <h5 className="font-weight-bold text-gradient">Email Gateways</h5>
      <p>Email gateways in our Lead Management System facilitate seamless communication by managing the flow of emails between your organization and potential leads. They ensure secure delivery, filter spam, and enforce compliance policies, thereby enhancing the effectiveness of your emails</p>

    </div>
  </div>
  <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-4">
    <div className="shadow rounded-large border p-4 h-100">
      <img src={twilio} className="img-fluid rounded-large mb-4" style={{ backgroundImage: 'linear-gradient(to right, rgba(238,50,72, .10), rgba(238,50,72, .10)), url("")' }}/>
      <h5 className="font-weight-bold text-gradient">SMS Gateways</h5>
      <p>SMS gateways in our Lead Management System enable efficient communication with leads through instant text messaging. They facilitate timely updates and alerts, ensuring that your messages reach potential customers securely and reliably and improves overall lead management efficiency.</p>

    </div>
  </div>
</div>
		</div>
		<div className="sec-space"></div>
		 <Footer />
     </div>
   )
 }

 export default Integrations