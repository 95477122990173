import React, { useState } from 'react';
 import Navbar from './navbar'
 import Footer from './footer'
 import bg1 from '../images/bg-1.jpg'
 import bg2 from '../images/bg-2.jpg'


 const TermsOfService = () => {


   return (
     <div>
         <Navbar />
       	<div className="spacex" style={{
    backgroundImage:
      "linear-gradient(to right, rgba(var(--pcrgb), .0), rgba(var(--pcrgb), .0))"
  }}>  
  <div className="sec-space"></div>
  <h1 className="font-weight-bold">Terms of Service</h1>
<div className="sec-space"></div>
      <h2 className="font-weight-bold">1. Acceptance of Terms</h2>
      <p>
        Welcome to DigiLead Studios (product of Digilitics Consulting Services LLP) ("Company", "we", "our", "us"). By accessing or using our tool ("Service"), you agree to comply with and be bound by these Terms of Service ("Terms"). These Terms apply to all users, including visitors, registered users, and anyone who accesses or uses our Service. If you do not agree to these Terms, you may not use our Service.
      </p>

      <h2 className="font-weight-bold">2. Description of Service</h2>
      <p>Our Service provides users with a comprehensive lead management system and appointment booking tool designed to streamline business processes. This includes, but is not limited to:</p>
      <ul>
        <li><strong>Lead Management:</strong> Tools to capture, track, and manage leads.</li>
        <li><strong>Appointment Booking:</strong> Scheduling tools that allow businesses to manage appointments efficiently.</li>
        <li><strong>Analytics and Reporting:</strong> Insights to help businesses optimize their processes.</li>
        <li><strong>Automation:</strong> Tools to automate repetitive tasks and improve productivity.</li>
      </ul>

      <h2 className="font-weight-bold">3. Eligibility to Use</h2>
      <p>By using our Service, you represent and warrant that:</p>
      <ul>
        <li>You are at least 18 years old.</li>
        <li>You have the legal authority to enter into this agreement.</li>
        <li>You will comply with all applicable laws and regulations.</li>
      </ul>

      <h2 className="font-weight-bold">4. User Responsibilities</h2>
      <p>You agree to:</p>
      <ul>
        <li>Provide accurate, current, and complete information during registration.</li>
        <li>Maintain the confidentiality of your account credentials.</li>
        <li>Notify us immediately of any unauthorized access to your account.</li>
        <li>Use the Service in compliance with these Terms and applicable laws.</li>
      </ul>
      <p>Prohibited Activities include:</p>
      <ul>
        <li>Using the Service for any unlawful purposes.</li>
        <li>Attempting to gain unauthorized access to the Service or other users' accounts.</li>
        <li>Transmitting any malicious code, viruses, or other harmful software.</li>
      </ul>

      <h2 className="font-weight-bold">5. Payment and Subscription</h2>
      <ul>
        <li><strong>Free Plan:</strong> We offer a free version of our Service with limited features.</li>
        <li><strong>Paid Plans:</strong> Our paid subscriptions offer additional features and are billed on a recurring basis.</li>
        <li><strong>Cancellation and Refunds:</strong> You may cancel your subscription at any time. Refunds are subject to our refund policy, which is detailed on our website.</li>
      </ul>

      <h2 className="font-weight-bold">6. Intellectual Property</h2>
      <p>
        All content, features, and functionality of the Service, including text, graphics, logos, and software, are owned by DigiLead Studios or its licensors and are protected by copyright, trademark, and other intellectual property laws.
      </p>
      <p>You are granted a limited, non-exclusive, non-transferable license to access and use the Service for your internal business purposes. You may not:</p>
      <ul>
        <li>Copy, modify, or distribute any part of the Service without prior written consent.</li>
        <li>Reverse-engineer or attempt to extract the source code of the Service.</li>
      </ul>

      <h2 className="font-weight-bold">7. Data Privacy</h2>
      <p>Your use of the Service is also governed by our Privacy Policy, which outlines how we collect, use, and protect your information.</p>

      <h2 className="font-weight-bold">8. Termination</h2>
      <p>
        We reserve the right to suspend or terminate your access to the Service at our discretion if you violate these Terms or if we believe your actions may cause harm to the Company or other users.
      </p>
      <p>
        Upon termination, your right to use the Service will cease immediately. If you wish to terminate your account, you may do so by contacting us directly.
      </p>

      <h2 className="font-weight-bold">9. Disclaimer of Warranties</h2>
      <p>
        The Service is provided on an "as-is" and "as-available" basis. We disclaim all warranties of any kind, whether express or implied, including but not limited to merchantability, fitness for a particular purpose, and non-infringement.
      </p>
      <p>We do not warrant that:</p>
      <ul>
        <li>The Service will meet your specific requirements.</li>
        <li>The Service will be uninterrupted, timely, secure, or error-free.</li>
        <li>Any errors in the Service will be corrected.</li>
      </ul>

      <h2 className="font-weight-bold">10. Limitation of Liability</h2>
      <p>
        To the maximum extent permitted by law, DigiLead Studios shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses.
      </p>

      <h2 className="font-weight-bold">11. Governing Law and Dispute Resolution</h2>
      <p>
        These Terms are governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.
      </p>
      <p>
        Any disputes arising out of or relating to these Terms shall be resolved through binding arbitration in courts of Hyderabad.
      </p>

      <h2 className="font-weight-bold">12. Changes to Terms of Service</h2>
      <p>
        We reserve the right to update or modify these Terms at any time. We will notify users of significant changes by posting the updated Terms on our website. Continued use of the Service after such changes constitutes acceptance of the new Terms.
      </p>
  <div className="sec-space"></div>
  </div>
		 <Footer />
     </div>
   )
 }

 export default TermsOfService