import { Link } from "react-router-dom";

 const Footer = () => {
  return        <div className="spacex shadow" style={{
    backgroundImage:
      "linear-gradient(to right, rgba(var(--scrgb), 1), rgba(var(--tcrgb), 1))"
  }}>
  <div className="text-white text-center pt-4 pb-4">
  <Link to="/" className="text-white">Home</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
  <Link to="/lead-management-system" className="text-white">Lead Management System</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
  <Link to="/healthcare-appointment-system" className="text-white">Healthcare Appointment System</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
  <Link to="/pricing" className="text-white">Pricing</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
  <Link to="/integrations" className="text-white">Integrations</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
  <Link to="/book-a-demo" className="text-white">Book A Demo</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
  <Link to='/privacy-policy' className="text-white">Privacy Policy</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
  <Link to='/terms-of-service' className="text-white">Terms Of Use</Link>
  <hr />
  © 2024 Digi Lead Studios. All Rights Reserved.
  </div>
</div>
;

 }
 export default Footer