
 import { Route, Routes } from 'react-router-dom';
 import Integrations from './pages/integrations';
 import Home from './pages/home';

 import LeadManagementSystem from './pages/lead-management-system';

 import HealthcareAppointmentSystem from './pages/healthcare-appointment-system';

 import Pricing from './pages/pricing';

 import BookADemo from './pages/book-a-demo';

 import TermsOfService from './pages/terms-of-service';

 import PrivacyPolicy from './pages/privacy-policy';


 function App() {
   return (
      <div className="App">
       <Routes>
        
         <Route path='/' element={<Home/>} />

         <Route path='/lead-management-system' element={<LeadManagementSystem/>} />

         <Route path='/healthcare-appointment-system' element={<HealthcareAppointmentSystem/>} />

         <Route path='/pricing' element={<Pricing/>} />

		     <Route path='/integrations' element={<Integrations/>} />

		     <Route path='/book-a-demo' element={<BookADemo/>} />

         <Route path='/privacy-policy' element={<PrivacyPolicy/>} />

         <Route path='/terms-of-service' element={<TermsOfService/>} />

       </Routes>
     </div>
   );
 }

 export default App;