import logo from '../images/logo.png';
import { Link } from "react-router-dom";

 const Navbar = () => {
  return        <div className="spacex shadow bg-white sticky-top">
  <div className="">
    <div className="primary-nav">
      <nav className="navbar navbar-expand-lg navbar-light p-0">
        <a href="/">
          <img src={logo} className="mb-2 mt-2" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto mt-4 mb-4">
		  <li className="nav-item">
			   <Link to="/" className="nav-link">Home</Link>
            </li>
			<li className="nav-item dropdown">
  <a
    className="nav-link dropdown-toggle"
    href=""
    id="navbarDropdownMenuLink"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    Tools
  </a>
  <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li>
			  <Link to="/lead-management-system" className="dropdown-item">Lead Management System</Link>
            </li>
            <li>
			  <Link to="/healthcare-appointment-system" className="dropdown-item">Healthcare Appointment System</Link>
            </li>
			</ul>
			</li>

      <li className="nav-item">
			  <Link to="/pricing" className="nav-link">Pricing</Link>
            </li>
			<li className="nav-item">
			  <Link to="/integrations" className="nav-link">Integrations</Link>
            </li>
            <li className="nav-item">
              <Link to="/book-a-demo" className="nav-link">
              Book A Demo
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="button gbtn text-white font-weight-600"
                href="tel:812 102 6060"
              >
                <i className="fa fa-mobile-alt" />
                &nbsp;&nbsp;812 102 6060
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>
;

 }
 export default Navbar